/* You can add global styles to this file, and also import other style files */

$Slate        : #8892b0;
$LightSlate   : #a8b2d1;
$LightestSlate: #ccd6f6;
$White        : #e6f1ff;

$Darkgray     : #575454;
$Papiro       : #E8DFDF;
$DarkPapiro   : #C8BBBB;

$MainFont: Calibre,San Francisco,SF Pro Text,-apple-system,system-ui,BlinkMacSystemFont,Roboto,Helvetica Neue,Segoe UI,Arial,sans-serif;
$CodeFont: SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace;
